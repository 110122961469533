import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Table } from "semantic-ui-react";
import Page from "../../../components/page/Page";
import StaffPermissionService from "../../../services/StaffPermissionService";
import withPermissionWrapper from "../../../security/withPermissionWrapper";
import { compose } from "redux";
import AdminDataSegregationService from "../../../services/admin/AdminDataSegregationService";

const AdminDataSegregationPage = (props) => {
  const { t } = props;

  const [isRunning, setIsRunning] = useState(false);

  const rows = [(<Table.Row key={'reconcile_all'}>
    <Table.Cell key={'reconcile_all_item'}>Reconcile all</Table.Cell>
    <Table.Cell key={'reconcile_all_action'}>

          <Button
              primary
              disabled={isRunning}
              onClick={() => {
                  AdminDataSegregationService.reconcile(null, null);
                  setIsRunning(true)
                }
              }
          >
            Reconcile all
          </Button>


    </Table.Cell>
  </Table.Row>)]

  return (
    <Page
      name="Data Segregation"
      header={t("DATA_SEGREGATION", "Data Segregation")}
    >

      <Table selectable>
        <Table.Header>
          <Table.Row key={"header"}>
            <Table.HeaderCell key={"item"}>
              {t("ADMIN_DATA_SEGREGATION_TYPE", "Item")}
            </Table.HeaderCell>
            <Table.HeaderCell key={"action"}>
              {t("ADMIN_DATA_SEGREGATION_ACTION", "Action")}
            </Table.HeaderCell>
          </Table.Row>
          {rows}
        </Table.Header>
      </Table>
    </Page>
  );
};

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageDataSegregation })(AdminDataSegregationPage);
