import React, { useEffect, useState, useRef, Fragment } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  Form,
  FormGroup,
  Grid,
  Icon,
  Label,
  Loader,
  Message,
  MessageHeader,
  Table,
  Modal,
  Checkbox,
  Segment
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import TriggerService from "../../services/admin/TriggerService";
import AdminTriggerViewModal from "../../components/admin/AdminTriggerViewModal";
import TRIGGER_STATE from "../../constants/TRIGGER_STATE";
import TrialService from "../../TrialService";
import { saveAs } from "file-saver";
import DataFormatService from "../../services/DataFormatService";
import { usePapaParse, useCSVReader, formatFileSize } from "react-papaparse";
import { Link } from "react-router-dom";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";

import AceEditor from "react-ace";
import SecondaryButton from "../../components/generic/SecondaryButton";
import _ from "lodash";
import ERROR_TRANSLATION_MAP from "../../utility/ErrorTranslation";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import StaffPermissionService from "../../services/StaffPermissionService";

const AdminTriggerListPage = ({ t }) => {
  const [error, setError] = useState(false);
  const [errorHeader, setErrorHeader] = useState(false);
  const [errorCsv, setErrorCsv] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggers, setTriggers] = useState([]);
  const [inactiveRerender, setInactiveRerender] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem('searchTerm') || ''
  );
  const [searchTermName, setSearchTermName] = useState(
    localStorage.getItem('searchTermName') || ''
  );
  const [searchTermTrigger, setSearchTermTrigger] = useState(
    localStorage.getItem('searchTermTrigger') || ''
  );
  const [searchTermAction, setSearchTermAction] = useState(
    localStorage.getItem('searchTermAction') || ''
  );
  const [searchTermCondition, setSearchTermCondition] = useState(
    localStorage.getItem('searchTermCondition') || ''
  );
  const [selected, setSelected] = useState(null);
  const [currentTriggerStateFilter, setCurrentTriggerStateFilter] = useState(
    TRIGGER_STATE.ACTIVE.value
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [col, setCol] = useState([]);
  const [val, setVal] = useState([]);
  const [triggerObjects, setTriggerObjects] = useState([]);

  const [shouldShowResults, setShouldShowResults] = useState(false);
  const toggleShouldShowResults = () => setShouldShowResults(!shouldShowResults);

  const [showAllFields, setShowAllFields] = useState(false);
  const toggleShowAllFields = () => setShowAllFields(!showAllFields)

  const { jsonToCSV } = usePapaParse();

  const previousTriggersRef = useRef([]);
  const { CSVReader } = useCSVReader();

  const getTriggerList = async () => {
    try {
      const triggerList = await TriggerService.getTriggers(
        currentTriggerStateFilter,
        searchTerm
      );
      if (triggerList) {
        setTriggers(triggerList);
        setLoading(false);
        setError(false);
        previousTriggersRef.current = _.cloneDeep(triggerList);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (e) {
      console.error("Error fetching triggers: ", e);
      setError(true);
    }
  };

  const onCheckChanged = (e, target, triggerId) => {
    if (target.checked) {
      const newSelectedItems = [...selectedItems];
      if (!newSelectedItems.some((t) => triggerId === t)) {
        newSelectedItems.push(triggerId);
      }
      setSelectedItems(newSelectedItems);
    } else {
      const newSelectedItems = [...selectedItems].filter((t) => {
        return triggerId !== t;
      });
      setSelectedItems(newSelectedItems);
    }
  };

  const onCheckAllChanged = (e, target) => {
    setIsSelectAllChecked(target.checked);
    if (target.checked) {
      const allTriggers = triggers.map((trigger) => trigger.id);
      setSelectedItems(allTriggers);
    } else {
      setSelectedItems([]);
    }
  };

  const toggleTriggerActive = async (feedback) => {
    const triggerIds = selectedItems;
    triggerIds.forEach(async (id) => {
      setInactiveRerender(true);
      await TriggerService.updateTriggerIsActive(
        id,
        false,
        feedback
      )
      setInactiveRerender(false);
    });
    setSelectedItems([]);
  };

const filteredTriggers = triggers.filter(t => {
  const nameMatch = !searchTermName || (t.name && t.name.toLowerCase().includes(searchTermName.toLowerCase()));
  const triggerMatch = !searchTermTrigger || (t.trigger && t.trigger.toLowerCase().includes(searchTermTrigger.toLowerCase()));
  const actionMatch = !searchTermAction || (t.action && t.action.toLowerCase().includes(searchTermAction.toLowerCase()));
  const conditionMatch = !searchTermCondition || (t.condition && t.condition.toLowerCase().includes(searchTermCondition.toLowerCase()));

  return nameMatch && triggerMatch && actionMatch && conditionMatch;
});

  useEffect(() => {
    LocalStorageHelper.setString('searchTerm', searchTerm);
    LocalStorageHelper.setString('searchTermName', searchTermName);
    LocalStorageHelper.setString('searchTermTrigger', searchTermTrigger);
    LocalStorageHelper.setString('searchTermAction', searchTermAction);
    LocalStorageHelper.setString('searchTermCondition', searchTermCondition);
  }, [searchTerm, searchTermName, searchTermTrigger, searchTermAction, searchTermCondition]);

  const handleResetSearch = (e) => {
    e.preventDefault();
    setSearchTerm('');
    setSearchTermName('');
    setSearchTermTrigger('');
    setSearchTermAction('');
    setSearchTermCondition('');
  };
//--------------

//Full edit/add JSON
  const [jsonError, setJsonError] = useState(null);
  const [failedJsonTriggers, setFailedJsonTriggers] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [shouldShowJson, setShouldShowJson] = useState(false);
  const toggleShouldShowJson = () => setShouldShowJson(!shouldShowJson);

  const onChangeTriggerJson = (newJson) => {
    try {
      const parsedJson = JSON.parse(newJson);
      setTriggers(parsedJson);
      setJsonError(null);
      setFailedJsonTriggers(null);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setJsonError(`Error, invalid JSON: ${error}`);
      setFailedJsonTriggers(newJson);
      return
    }
  };

  const defaultJsonProperties = [
    { key: 'id', defaultValue: 0 },
    { key: 'name', defaultValue: '' },
    { key: 'submissionWindow', defaultValue: null },
    { key: 'shouldBreakParentChain', defaultValue: null },
    { key: 'moduleSpecification', defaultValue: null }
  ];

  const submitTriggerJson = async (feedback) => {
    if (jsonError) {
      console.error('Cannot submit due to JSON error:', jsonError);
      return;
    };

    const promises = [];
    const previousTriggers = previousTriggersRef.current;

    triggers.forEach((obj, index) => {
      defaultJsonProperties.forEach(({ key, defaultValue }) => {
        if (!obj.hasOwnProperty(key)) {
          obj[key] = defaultValue;
        }
      });

      const previousObj = previousTriggers[index];

      if (!_.isEqual(obj, previousObj)) {
        if (obj.id !== 0 && obj.id != null && obj.id !== '') {
          promises.push(TriggerService.editTrigger(
            obj,
            feedback
          ));
        } else {
          promises.push(TriggerService.createTrigger(
            obj,
            feedback
          ));
        }
      } else {
        return
      }
    });

    const responses = await Promise.all(promises);

    if (!Array.isArray(responses)) {
      setError(true);
      return;
    };

    if (responses.length > 0 && responses[0].length > 0 && responses[0][0].key && responses[0][0].errorCode) {
      setValidationErrors(responses);
      return;
    }

    setValidationErrors([]);
    setShouldShowJson(false)

    return responses;
  };
  //--------------

  const onDownloadAsJsonClick = async () => {
    const response = await TriggerService.getTriggersAsJson();
    const blobData = new Blob([response.json], {
      type: "text/csv;charset=utf-8;",
    });

    const trial = await TrialService.getCurrentTrial();
    const filename = `${trial.name}-triggers.json`;

    saveAs(blobData, filename);
  };

  const onDownloadAsCsvClick = async () => {
    const rawTableData = DataFormatService.getTableData("triggerList");
    const correctedRowTableDate = rawTableData.map( row => {
       row.shift();
       return row;
    })
    const results = jsonToCSV(correctedRowTableDate, {escapeFormulae: true });
    //first column in the table is empty as it's a checkbox

    const blobData = DataFormatService.buildCsvBlob(results);

    const trial = await TrialService.getCurrentTrial();
    const filename = `${trial.name}-triggers.csv`;

    saveAs(blobData, filename);
  };

//------------------CSV UPLOAD
const expectedHeaders = [
  'Action',
  'Activation Window',
  'Condition',
  'Frequency',
  'ID',
  'Max occurrences',
  'Module Specification',
  'Name',
  'Offset',
  'Should break parent chain?',
  'Should notify?',
  'State',
  'Permitted Account State',
  'Trigger'
];

const headerTransformations = {
  'Action' : 'action',
  'Activation Window' : 'activationWindow',
  'Condition' : 'condition',
  'Frequency' : 'frequency',
  'ID' : 'id',
  'Max occurrences' : 'maxOccurrences',
  'Module Specification' : 'moduleSpecification',
  'Name' : 'name',
  'Offset' : 'offset',
  'Should break parent chain?' : 'shouldBreakParentChain',
  'Should notify?' : 'shouldNotify',
  'State' : 'isActive',
  'Permitted Account State':'permittedSubjectAccountStateLevel',
  'Trigger' : 'trigger'
};

  const handleCsvUploadAccepted = (results, acceptedFile) => {
    const fileName = acceptedFile.name || '';
    if (!fileName.toLowerCase().endsWith('.csv')) {
      setErrorCsv(true);
      return;
    };

    const value = results.data;
    const filtered = value.filter((_, i) => i !== 0);
      setCol(value[0]);
      setVal(filtered);

    const headers = value[0];
    const data = value.slice(1);
    if (!headers.every(header => {
     return expectedHeaders.includes(header)
    })) {
      setCol([]);
      setVal([]);
      setErrorHeader(true);
      return;
    };

    const transformedHeaders = headers.map(header => {
      if (headerTransformations.hasOwnProperty(header)) {
        return headerTransformations[header];
      } else {
        return headers;
      }
    });
  
    let triggerObjectsArray = data.map(row => {
      const obj = {};
      transformedHeaders.forEach((header, index) => {
        const value = (headers[index] === 'ID' || headers[index] === 'Max occurrences') ? Number(row[index]) 
          : (row[index] === 'TRUE' || row[index] === 'Active') ? true 
          : (row[index] === 'FALSE' || row[index] === 'Inactive') ? false : row[index];
        obj[header] = value;
      });
      return obj;
    });

    setTriggerObjects(triggerObjectsArray);
  };


  const handleCsvSubmit = async (feedback) => {
    const promises = [];
    triggerObjects.forEach(obj => {
      if (obj.id !== 0 && obj.id !== null && obj.id !== '') {
      promises.push(TriggerService.editTrigger(
        obj, 
        feedback
      ));
      } else {
        promises.push(TriggerService.createTrigger(
          obj, 
          feedback
        ));
      }
    });
    const responses = await Promise.all(promises);

    if (!Array.isArray(promises)) {
      setError(true);
      return;
    }

    setCol([]);
    setVal([]);
    setShouldShowResults(false);

    return responses;
  };
//-------------------------------


  useEffect(() => {
    getTriggerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, currentTriggerStateFilter, inactiveRerender, shouldShowJson, shouldShowResults]);

  const createTable = (triggers) => {
    if (!triggers) {
      return;
    }

const isActiveRow = triggers.some(trigger => trigger.isActive);

    const cellStyle = { textOverflow: "ellipses", whiteSpace: "nowrap" };

    const headerRow = (
      <Table.Header>
        <Table.Row>
            <Fragment key='header'>
              {isActiveRow && (
              <Table.HeaderCell key='header_checkbox'>
              <Checkbox
                  onChange={(e, target) =>
                    onCheckAllChanged(e, target)}
                  checked={isSelectAllChecked}
                />
              </Table.HeaderCell>
              )}
                            {!isActiveRow && (
              <Table.HeaderCell key='header_nocheckbox'>
        
              </Table.HeaderCell>
              )}
              <Table.HeaderCell  key='header_state'>
                {t('ADMIN_TRIGGER_TABLE_HEADER_STATE', 'State')}
              </Table.HeaderCell>
              <Table.HeaderCell  key='header_account_state_level'>
                {t('ADMIN_TRIGGER_TABLE_HEADER_PERMITTED_ACCOUNT_STATE_LEVEL', 'Permitted Account State')}
              </Table.HeaderCell>

            <Table.HeaderCell  key='header_name'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_NAME', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} key='header_trigger'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_TRIGGER', 'Trigger')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} key='header_action'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_ACTION', 'Action')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} key='header_condition'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_CONDITION', 'Condition')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_activation'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_ACTIVATIONWINDOW', 'Activation Window')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_offset'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_OFFSET', 'Offset')}
            </Table.HeaderCell>
            {showAllFields && (
              <>
            <Table.HeaderCell key='header_frequency'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_FREQUENCY', 'Frequency')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_occurrences'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_MAXOCCURRENCES', 'Max occurrences')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_notify'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_SHOULDNOTIFY', 'Should notify?')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_break'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_SHOULDBREAKPARENTCHAIN', 'Should break parent chain?')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_module'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_MODULESPECIFICATION', 'Module Specification')}
            </Table.HeaderCell>
            <Table.HeaderCell  key='header_id'>
              {t('ADMIN_TRIGGER_TABLE_HEADER_ID', 'ID')}
            </Table.HeaderCell>
            </>
            )}
            </Fragment>
        </Table.Row>
      </Table.Header>
    );

    const triggerRows = (
      <Table.Body>
        {filteredTriggers.map((trigger, index) => (
          <Fragment key={trigger.name}>
          <Table.Row
            active={trigger.id === selected}
            onClick={() => setSelected(trigger.id)}
            negative={!trigger.isActive}
          >
              {trigger.isActive && (
                <Table.Cell
                  width={1}
                  key={trigger.id + '_checkbox'}
                  verticalAlign='top'
                >
                  <Checkbox
                    onChange={(e, { checked }) => {
                      e.stopPropagation();
                      onCheckChanged(e, { checked }, trigger.id);
                    }}
                    checked={selectedItems.some((t) => t === trigger.id)}
                  />
                </Table.Cell>
              )}
                            {!isActiveRow && (
                <Table.Cell
                  width={1}
                  verticalAlign='top'
                >
                </Table.Cell>
              )}
            <Table.Cell>
              {trigger.isActive && (
                <Label color="green" horizontal>
                  {t(TRIGGER_STATE.ACTIVE.label)}
                </Label>
              )}
              {!trigger.isActive && (
                <Label color="red" horizontal>
                  {t(TRIGGER_STATE.INACTIVE.label)}
                </Label>
              )}
            </Table.Cell>
            <Table.Cell>{trigger.permittedSubjectAccountStateLevel}</Table.Cell>
            <Table.Cell>{trigger.name}</Table.Cell>
            <Table.Cell style={cellStyle}>{trigger.trigger}</Table.Cell>
            <Table.Cell style={cellStyle}>{trigger.action}</Table.Cell>
            <Table.Cell style={cellStyle}>{trigger.condition}</Table.Cell>
            <Table.Cell>{trigger.activationWindow}</Table.Cell>
            <Table.Cell>{trigger.offset}</Table.Cell>
            {showAllFields && (
              <>
                <Table.Cell>{trigger.frequency}</Table.Cell>
                <Table.Cell>{trigger.maxOccurrences}</Table.Cell>
                <Table.Cell>{JSON.stringify(trigger.shouldNotify)}</Table.Cell>
                <Table.Cell>{JSON.stringify(trigger.shouldBreakParentChain)}</Table.Cell>
                <Table.Cell style={cellStyle}>{trigger.moduleSpecification}</Table.Cell>
                <Table.Cell>{trigger.id}</Table.Cell>
              </>
            )}
            
          </Table.Row>
          </Fragment>
        ))}
      </Table.Body>
    );
    return (
      <>
        {headerRow}
        {triggerRows}
      </>
    );
  };

  const getTriggerStateFilterOptions = () => {
    return Object.values(TRIGGER_STATE).map((triggerState) => {
      return {
        key: triggerState.value,
        text: t(triggerState.label),
        value: triggerState.value,
      };
    });
  };

  const onTriggerStateFilterChange = (e, data) => {
    const value = data?.value;
    setCurrentTriggerStateFilter(value);
  };

  const showActiveInactiveUpdateInput = selectedItems.length > 0;

  const onCloseJson = () => {
    setShouldShowJson(false)
    setValidationErrors([]);
  };

  const handleCsvClose = () => {
    toggleShouldShowResults(false);
    setCol([]);
    setVal([]);
  };

  return (
    <Page
      name="Admin Trigger Utilities"
      header={t("ADMIN_TRIGGER_HEADER", "Admin Triggers")}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support:" + error.message}
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading && Array.isArray(triggers) && (
        <>
          <Form>
            <Form.Field>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Dropdown
                      fluid
                      selection
                      options={getTriggerStateFilterOptions()}
                      value={currentTriggerStateFilter}
                      onChange={onTriggerStateFilterChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Button
                      as={Link}
                      to="/app/utils/triggers/new"
                      fluid
                      primary
                    >
                      {t("ADMIN_TRIGGERS_NEW", "Create New Trigger")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon={<Icon name="search" />}
                iconPosition={"left"}
                placeholder="Search all..."
                value={searchTerm}
                onChange={(e, v) => {
                  setSearchTerm(v.value);
                }}
              />
            </Form.Field>
            <FormGroup widths='equal'>
              <Form.Input
                icon={<Icon name="search" />}
                iconPosition={"left"}
                placeholder="Search name..."
                value={searchTermName}
                onChange={(e, v) => 
                  setSearchTermName(v.value)
                }
              />
              <Form.Input
                icon={<Icon name="search" />}
                iconPosition={"left"}
                placeholder="Search trigger..."
                value={searchTermTrigger}
                onChange={(e, v) => 
                  setSearchTermTrigger(v.value)
                }
              />
              <Form.Input
                icon={<Icon name="search" />}
                iconPosition={"left"}
                placeholder="Search action..."
                value={searchTermAction}
                onChange={(e, v) => 
                  setSearchTermAction(v.value)
                }
              />
              <Form.Input
                icon={<Icon name="search" />}
                iconPosition={"left"}
                placeholder="Search condition..."
                value={searchTermCondition}
                onChange={(e, v) => 
                  setSearchTermCondition(v.value)
                }
              />
            </FormGroup>
            <Button secondary disabled={loading} onClick={handleResetSearch}>
                {t("ATTACHMENTS_RESET_BUTTON", "Reset search")}
              </Button>
          </Form>
          <AparitoSwitch
            checked={showAllFields}
            onChange={toggleShowAllFields}
            label={t("ADMIN_TRIGGER_SHOWALLCOLUMNS", "Show all columns?")}
          />
      {showActiveInactiveUpdateInput && (
        <>
          <Segment style={{width: '25%'}}>
            <h3>
              {t(
                'TRIGGER_LIST_ACTIVE_INACTIVE',
                'Update Active/Inactive'
              )}
            </h3>
            <Grid.Column width={4}>
            <ConfirmButtonWithFeedback
              buttonText={t(
                'TRIGGERS_CHANGE_STATUS_BUTTON',
                'Make Inactive'
              )}
              headerText={t(
                [
                  'TRIGGERS_CHANGE_STATUS_PROMPT_HEADER',
                  'TRIGGERS_CHANGE_STATUS_PROMPT_TEXT'
                ],
                'Make Inactive'
              )}
              contentText={t(
                'TRIGGERS_CHANGE_STATUS_PROMPT_TEXT',
                'Please confirm that you want to update these items'
              )}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={() => toggleTriggerActive()}
            />
          </Grid.Column>
          </Segment>
        </>
      )}
          <SecondaryButton
            style={{
              position: 'absolute',
              top: '455px',
              right: '28px'
            }}
            primary={shouldShowJson}
            noPadding
            rounded
            height={"30px"}
            width={"30px"}
            onClick={toggleShouldShowJson}>
            <span>{"{}"}</span>
          </SecondaryButton>
          {shouldShowJson && <Modal open={shouldShowJson} size='fullscreen'>
            <Modal.Content >
              <Modal.Description>
                <Message info><MessageHeader>Note for adding new triggers:</MessageHeader>Please add to the end of the list and match to the existing triggers format as closely as possible. Don't include an ID key and/or value.</Message>
                <AceEditor
                  mode="json"
                  height="100%"
                  theme="dracula"
                  name="fullTriggerEditor"
                  editorProps={{ $blockScrolling: true }}
                  value={failedJsonTriggers == null ? JSON.stringify(triggers, null, 2) : failedJsonTriggers}
                  style={{ minHeight: "600px", width: '100%' }}
                  onChange={onChangeTriggerJson}
                />
                <ConfirmButtonWithFeedback
                  buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                  headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                  contentText={t(
                    "ADMIN_TRIGGER_JSON_DETAILED",
                    "Please supply a reason why this modification is required"
                  )}
                  confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                  cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                  onConfirm={submitTriggerJson}
                  placeholderText={t("ADMIN_TRIGGER_JSON_PLACEHOLDER_TEXT", "Supply reason here.")}
                  mandatoryValidationText={t(
                    "ADMIN_TRIGGER_JSON_VALIDATION_TEXT",
                    "Please supply a reason for the change."
                  )}
                />
                <SecondaryButton style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  cursor: 'pointer',
                  borderRadius: '20px'
                }}
                  noPadding
                  height={"35px"}
                  width={"35px"}
                  onClick={onCloseJson}>
                  <span>{"X"}</span>
                </SecondaryButton>
                {jsonError && (
                  <Message
                    error
                    header="Error"
                    content={`Error, invalid JSON: ${jsonError}`}
                  />
                )}
                {validationErrors.length > 0 && (
                  <Message
                    error
                    header="Error"
                    content={`Validation Error: ${validationErrors[0][0].key} - ${ERROR_TRANSLATION_MAP[validationErrors[0][0].errorCode]}`}
                  />
                )}

              </Modal.Description>
            </Modal.Content>
          </Modal>}
          <Table fixed id={"triggerList"}>
              {createTable(triggers)}
            </Table>
     
            <AdminTriggerViewModal
              triggerId={selected}
              isOpen={selected !== null}
              setIsOpen={() => setSelected(null)}
              onUpdate={getTriggerList}
            />
        </>
      )}
      <Button onClick={onDownloadAsJsonClick}>
        {t("ADMIN_TRIGGER_DOWNLOADASJSON", "Download all as JSON")}
      </Button>
      <Button onClick={onDownloadAsCsvClick}>
        {t("ADMIN_TRIGGER_DOWNLOADASCSV", "Download above as CSV")}
      </Button>

      <CSVReader 
        onUploadAccepted={handleCsvUploadAccepted}
        onError={(err) => console.error(err)}
        config={{ worker: true }}
        noDrag
      >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }) => (
        <>
          <div {...getRootProps()}>
            {acceptedFile ? (
              <>
                <div style={{
                  position: 'relative',
                  maxWidth: '300px',
                  backgroundColor: '#f3f3f3',
                  borderRadius: '10px',
                  padding: '10px',
                  marginTop: '15px',
                  color: '#808080'
                }}>
                <div>
                    <p>{acceptedFile.name}</p>
                    <span>{formatFileSize(acceptedFile.size)}</span>
                  </div>
                <div>
                  <ProgressBar />
                </div>
                    <div {...getRemoveFileProps()} style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                      cursor: 'pointer'
                    }}>
                  <Remove color={"red"} />
                </div>
                </div>
              </>
            ) : (
              <Button style={{ marginTop: '5px'}} onClick={toggleShouldShowResults}>{t("ADMIN_TRIGGER_UPLOADCSV", "Upload CSV File")}</Button>
            )}
            </div>
            {errorCsv && (
              <Message
                error
                header={t("ERRORMESSAGECSV_TITLE", "Error with CSV")}
                content={t("ERRORMESSAGECSV_CONTENT", "Invalid file format. Please upload a CSV file.")}
              />
            )}
            {errorHeader && (
              <Message
                error
                header={t("ERRORMESSAGEHEADERS_TITLE", "Error with CSV")}
                content={t("ERRORMESSAGEHEADERS_CONTENT", "CSV file headers do not match the expected format.")}
              />
            )}
            {col.length > 0 && <Modal open={shouldShowResults} size='fullscreen'>
              <Modal.Content scrolling>
                <Modal.Description>
                  <div>
                  <Icon name='square full' color='orange'/> - {t("ADMIN_TRIGGER_TOEDIT", "Trigger to be edited")} 
                  <Icon style={{marginLeft: '10px'}} name='square full' color='teal'/> - {t("ADMIN_TRIGGER_TOADD", "New Trigger to be added")}
                  </div>
                  <Table size='small'>
                    <Table.Header>
                      <Table.Row>
                        {col.length > 0 &&
                          col.map((col, i) => <Table.HeaderCell width={1} key={i}>{col}</Table.HeaderCell>)}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {val.map((val, i) => (
                        <Table.Row key={i} className={val.hasOwnProperty('12') && val['12'] === ""  ? 'withoutIdCSV' : 'withIdCSV'}>             
                          {val.map((v, i) => (     
                            <Table.Cell width={1} key={i}>{v} </Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <ConfirmButtonWithFeedback
                    buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                    headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                    contentText={t(
                      "ADMIN_QUESTIONNAIREDEFINITION_CLONE_DETAILED",
                      "Please supply a reason why this modification is required"
                    )}
                    confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                    cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    onConfirm={handleCsvSubmit}
                    placeholderText={t("ADMIN_QUESTIONNAIREDEFINITION_CLONE_PLACEHOLDER_TEXT", "Supply reason here.")}
                    mandatoryValidationText={t(
                      "ADMIN_QUESTIONNAIREDEFINITION_CLONE_VALIDATION_TEXT",
                      "Please supply a reason for the change."
                    )}
                  />
                 <SecondaryButton style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                      cursor: 'pointer',
                      borderRadius: '20px'
                    }} noPadding height={"40px"} width={"40px"} onClick={handleCsvClose}><span>{"X"}</span></SecondaryButton>
                </Modal.Description>
              </Modal.Content>
            </Modal>
            }
        </>
      )}
    </CSVReader>

    <div>

    </div>
    </Page>
  );
};

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageTriggers })(AdminTriggerListPage);
