import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Message, Modal, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import AuthService from '../../services/AuthService';
import PermissionsService from '../../services/PermissionsService';
import StaffGroupRoleMapping from './StaffGroupRoleMapping';
import StaffSubjectLinking from './StaffSubjectLinking';
import StaffDetails from './StaffDetails';
import StaffService from '../../StaffService';
import { sortByLabel } from '../../helpers/sortFunctions';

const StaffForm = ({ t, staff, errorMessage, onSubmit, onError }) => {
  const [allGroups, setAllGroups] = useState();
  const [isPrimarySuperAdmin, setIsPrimarySuperAdmin] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isCurrentUserASuperAdmin, setIsCurrentUserASuperAdmin] = useState(false);
  const [isEditingOwnAccount, setIsEditingOwnAccount] = useState(false);
  const [showIsEditingOwnAccountWarning, setShowIsEditingOwnAccountWarning] = useState();
  const [areLinkingControlsVisible, setAreLinkingControlsVisible] = useState(false);

  const [staffData, setStaffData] = useState({
    staffDetails: {
      superAdmin: staff?.superAdmin || false,
      firstName: staff?.firstName ?? '',
      lastName: staff?.lastName ?? '',
      email: staff?.email ?? '',
      drugManager: staff?.drugManager ?? false
    },
    groupRoles: [],
    linkingData: {
      isLinked: staff?.linkedSubjectId != null,
      isCreateLinkedAccountChecked: false,
      selectedLinkedGroupDropdown: '',
      selectedLinkedGroup: '',
    }
  });

  useEffect(() => {
    const populateGroups = async () => {
      const items = await StaffService.getGroupsForStaffManagement();
      setAllGroups(items.sort(sortByLabel));
    }
    populateGroups();

    if (staff) {
      setIsPrimarySuperAdmin(PermissionsService.isPrimarySuperAdmin(staff));

      const initialGroupRoles = [];
      for (const groupRole of staff.groupMappings) {
        initialGroupRoles.push({
          group: groupRole.group.code,
          roles: groupRole.roles.map((role) => role.code),
        });
      }
      setStaffData({
        ...staffData,
        groupRoles: initialGroupRoles
      });
    }

    AuthService.getIsSuperAdmin().then((isSuperAdmin) => {
      setIsCurrentUserASuperAdmin(isSuperAdmin);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showIsEditingOwnAccountWarning != null) {
      return;
    }

    StaffService.getMyProfile().then(profile => {
      if (staff.id === profile.id) {
        setShowIsEditingOwnAccountWarning(true);
        setIsEditingOwnAccount(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIsEditingOwnAccountWarning]);

  const handleSubmit = (event) => {
    const staff = {
      ...staffData.staffDetails,
      groupRoles: staffData.groupRoles,
      ...staffData.linkingData
    };

    if (staff.isCreateLinkedAccountChecked) {
      //if not overridden make sure dropdown value selected
      if (staff.isOverrideGroupChecked === false &&
        areLinkingControlsVisible &&
        staff.selectedLinkedGroupDropdown.trim().length === 0
      ) {
        onError && onError(t('STAFF_FORM_SELECT_GROUP_ERROR', 'Please select a group'));
        return;
      }

      //subject group is already set to the override value
      //if group selected from the dropdown then use this instead
      if (staff.isOverrideGroupChecked === false) {
        staff.selectedLinkedGroup = staff.selectedLinkedGroupDropdown;
      }
    }

    const promise = onSubmit(staff, isEditingOwnAccount);

    // If we have a promise, lets use that to determine when to show indication of submission
    if (promise) {
      setSubmitting(true);
      promise.finally(() => {
        setSubmitting(false);
      });
    }
  };

  const canSubmit = !submitting;

  return (
    <Form onSubmit={handleSubmit} error={errorMessage?.length > 0}>
      {errorMessage?.length > 0 && <Message negative header={t('STAFF_FORM_ERROR_TITLE')} content={errorMessage} />}

      <Modal
        size={'small'}
        open={showIsEditingOwnAccountWarning === true}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>{t('STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_HEADER', 'You are editing your own account')}</Modal.Header>
        <Modal.Content>
          {t('STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_TEXT', 'As you are editing your own user account. When you save the changes, you will be immediately logged out to allow all changes to take effect fully.')}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowIsEditingOwnAccountWarning(false)}>{t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}</Button>
        </Modal.Actions>
      </Modal>

      <Grid columns={'equal'}>
        <Grid.Column>
          <Segment>
            <Header>{t('STAFF_FORM_DETAILS_HEADER')}</Header>
            {isPrimarySuperAdmin && (
              <Message info visible>
                <Message.Header>
                  <Icon name={'warning sign'} /> WARNING
                </Message.Header>
                {t('PRIMARY_SUPERADMIN_EDIT_WARNING', 'This is the Primary Super Admin, and some details cannot be edited')}
              </Message>
            )}

            <StaffDetails
              isCurrentUserASuperAdmin={isCurrentUserASuperAdmin}
              initialStaffDetails={staffData.staffDetails}
              onChange={(data) => {
                setStaffData({
                  ...staffData,
                  staffDetails: data
                });
              }}
            />

          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <Segment>
              <StaffGroupRoleMapping
                allGroups={allGroups}
                selectedGroupRoles={staffData.groupRoles}
                onSelectionUpdate={(data) => {
                  setStaffData({
                    ...staffData,
                    groupRoles: data
                  });
                }}
              />
            </Segment>
          </Grid.Row>
          <Grid.Row>
            <Segment style={{ marginTop: 20 }}>
              <StaffSubjectLinking
                allGroups={allGroups}
                staff={staff}
                initialLinkingData={staffData.linkingData}
                emailAddress={staffData.staffDetails?.email}
                onLinkingDataUpdate={(data) => {
                  setStaffData({
                    ...staffData,
                    linkingData: data
                  });
                }}
                onLinkingControlVisibilityChange={(isVisible) => {
                  setAreLinkingControlsVisible(isVisible);
                }}
              />
            </Segment>
          </Grid.Row>
        </Grid.Column>
        <Grid.Row>
          <Grid.Column>
            <Message warning visible={isEditingOwnAccount === true} header={t('STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_HEADER', 'You are editing your own account')} content={t('STAFF_FORM_EDITING_OWN_ACCOUNT_WARNING_TEXT', 'As you are editing your own user account. When you save the changes, you will be immediately logged out to allow all changes to take effect fully.')} />
            <Button
              type='submit'
              loading={submitting}
              primary
              disabled={!canSubmit}
            >
              {t('GLOBAL_BUTTON_SAVE')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

export default withTranslation()(StaffForm);
